import * as React from 'react';
import axios from 'axios'
import {RemoveScroll} from 'react-remove-scroll';
import Modal from './Components/modal';
import 'mapbox-gl/dist/mapbox-gl.css';
import {useJsApiLoader, GoogleMap, Marker, InfoWindow, MarkerWithLabel } from '@react-google-maps/api'
import './App.css'
import useGeolocation from "react-hook-geolocation";
import Geocode from "react-geocode";
import Navbar from './Components/Navbar';
import Message from './Components/message';
import { RepeatOneOn } from '@mui/icons-material';
import { position } from '@chakra-ui/react';

class Size {
  constructor(width, height) {
    this.width = width;
    this.height = height;
  }
}
// api de google maps
Geocode.setApiKey(process.env.REACT_APP_MAPKEY);
let Id = ""
let IdLevel=""
let showLevel="rectangle"
let load = {}
let pos={}
let show = false
let l =[]

//creation du logo
function createCenterControl() {
  const image = document.createElement("img")
  image.src="https://yofitt.com/wp-content/uploads/2020/11/Yofitt-Brand-Assets-06.png"
  image.className="logo"
  image.addEventListener("click", () => {

    window.location.href = "https://yofitt.com";
  });

  return image;
}

//creation du 2eme filtre
function createCenterControlButton(texte, setGym, setOpen, resizePin, el, logo) {
  const button = document.createElement("button")
  const span = document.createElement("span")
  button.type = "button";
  button.className ="filterBut"
  button.style.border ="3px solid white"
  button.style.backgroundColor="white"
  button.style.color ="black"
span.style.fontFamily="'Dosis', sans-serif"
span.style.fontWeight ="700"
  button.style.fontWeight ="800"

  button.style.width ="70px"
  button.style.height="45px"
  button.style.position="relative"
  button.style.borderRadius="5px"
  button.style.cursor="pointer"
  button.style.textTransform="uppercase"
  button.style.textAlign="left"
 button.style.fontFamily="'Dosis', sans-serif"

if (texte === "yo prime" || texte === "yo gold" ) {
  button.style.fontStyle ="italic"
}

if(texte === "tout"){
  button.textContent = texte
  button.style.paddingTop = "15px"
  button.style.fontSize = "14px"
 
}else{
  span.textContent = texte
  span.style.color = el.color
  button.style.fontSize = "13px"
  button.style.fontStyle = 'italic'
span.style.fontStyle = 'italic'
  button.textContent="yo "
 span.style.fontSize = "18px"
}
button.addEventListener("click", () => {
  load.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].clear()
  setOpen(false)
  resizePin()

    if (texte === "tout") {
      showLevel="rectangle"
      IdLevel = ""
      for (let index = 0; index < l.length; index++) {
        document.getElementById(l[index].name).style.border = "3px solid white"
      }
      document.getElementById("tout").style.border = "3px solid black"
    }else{
      IdLevel = el._id
      button.style.border ="3px solid "+el.color
      document.getElementById("tout").style.border = "3px solid white"
      for (let index = 0; index < l.length; index++) {
        if (l[index].name != texte) {
          document.getElementById(l[index].name).style.border = "3px solid white"
        }
      }
    }
  
    axios
.get(`https://yofitt-2e712.ew.r.appspot.com/api/gyms/filtered/gyms?activities=${Id}&level=${IdLevel}`)
.then((res) => {
  const gyms = res.data
  setGym(gyms)
})
  
});




  button.appendChild(span)
  return button;
}

function createPositionButton(load, positioning) {
  const button = document.createElement("button")
  const image = document.createElement("img")
  button.className="buttonPosition"
  image.src="https://cdn-icons-png.flaticon.com/512/82/82029.png"
  button.type = "button";
  image.className="logoReposition"
  button.addEventListener("click",  () => {
    let a = 0
    positioning(a)
 /* if (navigator.geolocation) {
  navigator.geolocation.getCurrentPosition(function () {}, function () {}, {});
  navigator.geolocation.getCurrentPosition(
      (position) => {

        pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        show = true
        load.setCenter(pos);
      }
    ,errorCallback,{
      timeout:5000,enableHighAccuracy: true
  })
console.log(pos)
console.log(show)
  } else 
  {
    console.log("failed")
  }
*/
  });
  button.appendChild(image)
  return button;
}
function errorCallback(){
  console.log("positioning error")
}
function App() {
  //declaration des constantes
  const elRefs = React.useRef([])
  const geolocation = useGeolocation();
  const refMap = React.useRef(null);
  const [zoom, setZoom] = React.useState(13);
  const[center, setCenter] = React.useState({lat: 33.569857540665, lng:-7.661262860806869})
  const [gym,setGym]= React.useState([])
  const [levels,setLevels] = React.useState([])
  const [categories,setCategories]= React.useState([])
  const [open,setOpen]= React.useState(false)
  const [openMsg,setOpenMsg]= React.useState(true)
  const [showInfo,setShowInfo]= React.useState(false)
  const [info, setInfo] =  React.useState({})
  const [levelName, setLevelName] =  React.useState("")
  const [sizes, setSizes] = React.useState([])
  const [bool1, setBool1] = React.useState(null);
  let incr = 0
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPKEY,
  })
  const Div = document.createElement("div");
  const Div2 = document.createElement("div");
  const centerControlDiv = document.createElement("div");
  const centerControl = createCenterControl();
  var iconPin = {
    path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    fillColor: 'blue',
    fillOpacity: 1,
    scale: 0.03, //to reduce the size of icons
   };


//affectation du classname
  Div2.className = "ShadowNavbar"
  Div.className="bottomShadow"

//connexion à la base de données
  const getData = async () => {
    await  axios
        .get(' https://yofitt-2e712.ew.r.appspot.com/api/gyms/')
        .then((res) => {
          const gyms = res.data
          setGym(gyms)

        })
  }
  const getCategories = async () => {
    await  axios
        .get(' https://yofitt-2e712.ew.r.appspot.com/api/activities/')
        .then((res) => {
          const cat= res.data
          setCategories(cat)
         // setAutre(cat[cat.length - 1])
        //  cat.pop()
        })
  }
  const handleZoomChanged = () => {

    if (refMap.current) {

    if (load.zoom >= 14) {
      setBool1(true);
    } else {
      setBool1(false);
    }

    }else{
      console.log("nope")
    }
  }
  //handle open for models
const handleOpen =  (information,e, key)=>{
  load.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(Div);
  load.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].clear();
  setInfo(information)
  levelsBar(information)
  sizes[key]= {width: 35, height: 42}
  for (let index = 0; index < sizes.length; index++) {
    if (index != key) {
      sizes[index]= {width: 25, height: 30}
    }
  }
  
  setOpen(true)

}

const levelsBar = (gym)=>{
  if (gym.level?.color) {
    setLevelName(gym.level.name)
    if (gym.level.color === "#FF003B") {
      showLevel="rectangleRouge"
     }else if (gym.level.color === "#FFBB00"){
      showLevel="rectangleGold"
     }
  }
else{
  setLevelName("")
  showLevel="rectangle"
}
}
const uriFunction = (gym) => {
  let u
  if (gym.level?.color) {
    if (gym.level.color === "#FF003B") {
      u=require("./assets/images/pin-red.png")
     
       return u
     }else if (gym.level.color === "#FFBB00"){
       u = require("./assets/images/pin-yellow.png")
       return u
     }
  }
else{
  u=require("./assets/images/pin-dark.png")
  return u
}
}
const handleCloseCurrentPos=()=>{
  setShowInfo(false)
}
//functions for filter
function filter(event, id){
Id = id
setOpen(false)
resizePin()
//console.log("from filter", IdLevel)
for (let index = 0; index < categories.length; index++) {
  if (categories[index]._id !== id) {
    document.getElementById(categories[index]._id).style.backgroundColor="#9CA09F"
  //  document.getElementById(autre._id).style.backgroundColor="#9CA09F"
  }else{

      document.getElementById(id).style.backgroundColor=categories[index].coverColor

    
  }
  load.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].clear()
  
}
axios
.get(` https://yofitt-2e712.ew.r.appspot.com/api/gyms/filtered/gyms?activities=${id}&level=${IdLevel}`)
.then((res) => {
  const gyms = res.data
  setGym(gyms)
})

  }
const tout= ()=> {
  Id = ""
  setOpen(false)
  resizePin()
 // console.log("from tout", Id, "level", IdLevel)
  for (let index = 0; index < categories.length; index++) {

      document.getElementById(categories[index]._id).style.backgroundColor=categories[index].coverColor

  }

//  document.getElementById(autre._id).style.backgroundColor=autre.coverColor
axios
  .get(` https://yofitt-2e712.ew.r.appspot.com/api/gyms/filtered/gyms?activities=${Id}&level=${IdLevel}`)
  .then((res) => {
    const gyms = res.data
    setGym(gyms)
  })
  load.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].clear()
}

// handle load for the map
const handleLoad = async (map)=>{
  getData()
  let but = []

  const FilterDiv = document.createElement("div");
  const buttonPosition = createPositionButton(load, positioning)
  await  axios
    .get(' https://yofitt-2e712.ew.r.appspot.com/api/level')
    .then((res) => {
      l= res.data
    setLevels(l)
   // console.log(l)
    for (let index = 0; index < l.length; index++) {
      but.push(createCenterControlButton(l[index].name, setGym, setOpen, resizePin, l[index], l, centerControl))
    }
    })
    const tout = createCenterControlButton("tout", setGym, setOpen, resizePin, l[0], l)
    for (let index = 0; index < but.length; index++) {
     // console.log("yo" + l[index].name.charAt(0).toUpperCase() + l[index].name.slice(1))
      but[index].id =l[index].name;
    }
   // yoPrime.id ="yoPrime"
   // yoGold.id = "yoGold"
    tout.id ="tout"
  FilterDiv.className ="filter"
    if(incr === 0){
      centerControlDiv.appendChild(centerControl);
      FilterDiv.appendChild(tout)
     // FilterDiv.appendChild(yoPrime)
     // FilterDiv.appendChild(yoGold)
     for (let index = 0; index < but.length; index++) {
      FilterDiv.appendChild(but[index])
    }
      Div2.appendChild(FilterDiv)
      incr++
    }
    map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(centerControlDiv);
    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(Div2);
    map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(buttonPosition);
  //  map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(FilterDiv);

}
const resizePin=()=>{
  for (let index = 0; index < sizes.length; index++) {
    sizes[index]={width: 25, height: 30}
      
    }
}
const positioning = (a)=>{
  //console.log("hello")
  if(geolocation.latitude && geolocation.longitude){
    pos={
      lat: geolocation.latitude , lng:geolocation.longitude
    }
    var marker = new window.google.maps.Marker({
      position: pos,
      icon: iconPin,
  });
  setShowInfo(true)
    if(a === 0){
      load.setCenter(pos)
      marker.setMap(load);
    }
  a++
    show=true
  }
//  console.log(pos)
}
// useEffect
  React.useEffect(() => {
  getData()
 // add or remove refs
  console.log(gym[0])
  getCategories()

  }, [])

  // return of components
  if(!isLoaded){
    return <p>none</p>
  }

  return (
    <div className="App">
     <Navbar cat={categories} filter={filter} tout={tout}  />
     <div className="map">
     <GoogleMap 
      ref={refMap}
      center={center}   
      zoom={zoom} 
      mapContainerStyle={{width:'100%', height:'100%'}} 
      options={{
        zoomControl:false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl:false,
        mapId:'aafaee6be567ade6'
   }}
    onLoad={map => {
      handleLoad(map)
      load = map
   }

}  
    onZoomChanged={handleZoomChanged}
   >
    {openMsg && <Message setOpenMessage={setOpenMsg} message1={"Les salles de sport"} message2={" sont incluses dans le pack"} level1={"YO PRIME"} level2={"YO GOLD"}/>}
   {gym &&  gym.map((g, key) => (
    sizes.push(new Size(25, 30))
))}  
   {gym &&  gym.map((g, key) => (
  
  <div >
  <Marker label={{text:bool1 ? `${g.name}`:' ',color:g.level?.color ? g.level?.color:'black', fontSize:'12px', fontWeight:'bold', className:'markerLab'}}  ref={(el) => (elRefs.current[key] = el)}  onClick={(e)=>handleOpen(g,e, key)} options={{icon:{url: uriFunction(g), scaledSize: sizes[key]} }} animation={window.google.maps.Animation.DROP} key={key} position={{lat:parseFloat(g.location?.lat), lng:parseFloat(g.location?.lng)}} />
  </div> 
  

))} 
    {open && <Modal key={info._id} setOpenModal={setOpen} gym = {info} map={load} logo={centerControl}  showLevel={ showLevel} level={levelName} resizePin={resizePin}/>}



{showInfo &&  <InfoWindow

            position={pos}
            onCloseClick={handleCloseCurrentPos}
          >
            <div className="info">
              <p >My current position</p>
            </div>
          </InfoWindow>}

   </GoogleMap>
     </div >

   
    </div>
    
  );
}

export default App;